.useParentGreenBorder .rbc-month-view,
.useParentGreenBorder .rbc-time-view,
.useParentGreenBorder .rbc-agenda-view {
  @apply border-parent-green;
}

.useParentGreenBorder .rbc-header {
  @apply !border-l-parent-green border-opacity-60;
}

.useParentGreenBorder .rbc-timeslot-group {
  @apply !border-b-parent-green border-opacity-60;
}

.useParentGreenBorder .rbc-time-content > * + * > .rbc-timeslot-group,
.useParentGreenBorder .rbc-time-header > .rbc-row > * + .rbc-timeslot-group,
.useParentGreenBorder .rbc-day-bg,
.useParentGreenBorder .rbc-month-view .rbc-header + .rbc-header {
  @apply !border-l-parent-green border-opacity-60;
}

.useParentGreenBorder .rbc-time-content,
.useParentGreenBorder .rbc-month-row + .rbc-month-row {
  @apply !border-t-parent-green border-opacity-60;
}

.useParentGreenBorder .rbc-agenda-content tr {
  @apply !border-parent-green border-opacity-60;
}

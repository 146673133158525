.rbc-month-view,
.rbc-time-view,
.rbc-agenda-view {
  @apply rounded-lg border-2 border-admin-blue bg-white;
}

.rbc-month-view {
  @apply  max-h-[calc(100vw-78px)] sm:max-h-full;
}

.rbc-date-cell.rbc-now > a,
.rbc-date-cell.rbc-now > a:active,
.rbc-date-cell.rbc-now > a:visited {
  @apply !bg-teacher-blue;
}

.rbc-date-cell {
  @apply text-teacher-blue;
}

.rbc-date-cell.rbc-off-range {
  @apply opacity-60;
}

.rbc-date-cell > a {
  @apply flex justify-center items-center w-8 h-8 leading-snug rounded-full p-1 mt-1 !important;
}

.rbc-date-cell:hover:not(.rbc-now) > a {
  @apply !bg-breadcrumb-blue;
}

.rbc-toolbar,
.rbc-toolbar-label {
  @apply !text-teacher-blue text-xs md:text-base !p-0 md:!px-2;
}

.rbc-toolbar .rbc-btn-group:nth-child(1) > div:nth-child(2) > div {
  @apply rounded-lg border-2 border-teacher-blue !min-w-0;
}

.rbc-toolbar .rbc-btn-group:nth-child(1) > div:nth-child(2) > div button {
  @apply w-full bg-background !important;
}

.rbc-toolbar .rbc-btn-group:nth-child(1) > div:nth-child(2) > div button span {
  @apply !text-teacher-blue !px-2 md:!px-4;
}

.rbc-toolbar .rbc-btn-group:nth-child(3) div {
  @apply !text-teacher-blue capitalize scale-90 text-xl;
}

.rbc-toolbar .rbc-btn-group:nth-child(3) > div > div > div:nth-child(2) {
  @apply !rounded-lg bg-breadcrumb-blue text-sm md:text-base font-semibold !pl-3 !pr-10 md:!pl-6 md:!pr-14;
}

.rbc-toolbar .rbc-btn-group:nth-child(3) > div > div > button::before {
  content: ">";
  @apply text-teacher-blue block absolute top-2 left-2 h-6 w-6 text-xl rotate-90 scale-y-150 scale-75 translate-x-6 md:translate-x-3 translate-y-1 font-light;
}

.rbc-toolbar .rbc-btn-group:nth-child(3) > div > div > button svg {
  @apply !hidden;
}

.rbc-header {
  @apply text-teacher-blue !border-l-admin-blue border-opacity-50;
}

.rbc-month-header .rbc-header {
  @apply pt-1;
}

.rbc-month-view .rbc-event {
  @apply sm:border sm:!border-teacher-blue;
}

.rbc-time-view > .rbc-time-header > .rbc-row:first-child {
  @apply border-b-0;
}
.rbc-time-view > .rbc-time-header > .rbc-row:nth-child(2) {
  @apply hidden;
}

.rbc-event {
  @apply bg-inherit;
}

.rbc-time-gutter {
  @apply text-xs text-teacher-blue text-opacity-60;
}

.rbc-timeslot-group {
  @apply border-b-admin-blue min-h-60 border-opacity-50;
}

.rbc-time-content > .rbc-day-slot.rbc-time-column:nth-child(3) > .rbc-timeslot-group,
.rbc-time-content > * + *:nth-child(2) > .rbc-timeslot-group,
.rbc-time-header .rbc-header:nth-child(2)  {
  @apply border-l-0;
}

.rbc-time-content > * + * > .rbc-timeslot-group,
.rbc-time-header > .rbc-row > * + .rbc-timeslot-group {
  @apply !border-l-admin-blue border-opacity-50;
}

.rbc-day-bg:not(:first-child),
.rbc-month-view .rbc-header + .rbc-header {
  @apply !border-l-admin-blue border-opacity-50 !border-l-0 sm:!border-l;
}

.rbc-time-content {
  @apply border-t-admin-blue border-opacity-50;
}

.rbc-month-row + .rbc-month-row {
  @apply border-t-admin-blue border-opacity-50 border-t-0 sm:border-t;
}

.rbc-time-header.rbc-overflowing {
  @apply border-r-0;
}

.rbc-current-time-indicator,
.rbc-current-time-indicator::before {
  @apply bg-calendar-indicator-yellow z-10;
}

.rbc-agenda-view table,
.rbc-agenda-view table tbody > tr > td + td {
  @apply border-0;
}

.rbc-agenda-view table:first-child thead {
  @apply hidden;
}

.rbc-agenda-time-cell > span {
  @apply block;
}

.rbc-agenda-event-cell,
.rbc-agenda-time-cell,
.rbc-agenda-date-cell {
  @apply !p-0;
}

.rbc-agenda-time-cell .rbc-continues-after:after,
.rbc-agenda-time-cell .rbc-continues-prior:before {
  content: '';
  @apply hidden;
}

/* third party stuff has no classes on the divs at all */
[style="position: fixed; inset: 0px; z-index: 2000;"] > div {
  @apply !rounded-lg !border-2 border-table;
}

[style="position: fixed; inset: 0px; z-index: 2000;"] [role="menuitem"] {
  @apply !text-teacher-blue;
}

[style="position: fixed; inset: 0px; z-index: 2000;"] [style*="color: rgb(255, 64, 129);"] {
  @apply !text-table;
}

[style="position: fixed; inset: 0px; z-index: 2000;"] [role="menuitem"]:hover {
  @apply !text-white !bg-teacher-blue;
}

.rbc-month-view .rbc-today {
  @apply bg-white sm:bg-[#f5f5f5];
}

.rbc-month-row .rbc-row-content .rbc-row:nth-child(2) .rbc-row-segment .rbc-event:first-child {
  @apply relative;
}
.rbc-month-row .rbc-row-content .rbc-row:nth-child(2) .rbc-row-segment .rbc-event:first-child::before {
  content: "";
  @apply absolute block w-2 h-2 rounded-full sm:hidden bg-[#d9d9d9] left-5;
}

.rbc-show-more {
  @apply hidden sm:block;
}
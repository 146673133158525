@tailwind base;
@tailwind components;
@tailwind utilities;

body, #root {
  min-height: 100vh;
}

@layer utilities {
  .print-break-inside-avoid {
    break-inside: avoid-page;
  }
}

@media print {
  * {
    margin-top: 0 !important;
    margin-bottom: 1px !important;
    padding: 0 !important;
  }

  body, label, input, span {
    font-size: 10px !important;
  }

  .text-teacher-blue {
    font-size: 8px !important;
  }
}